import TaskStepper from "./components/Stepper";
import Logo from "./components/Logo";
import Background from "./components/Background";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import tasks from "./data/tasks.json";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Logo />
      <meta name="robots" content="noindex, nofollow" />
      <main className="pt-[100px]">
        <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={`/${tasks[1].id}`} />}
          />
          <Route
            path="/v2"
            element={<Navigate to={`/v2/${tasks[0].id}`} />}
          />
          <Route
            path="/v2/:taskId"
            element={<TaskStepper type="vertical" version="v2" />}
          />
          <Route
            path="/:taskId"
            element={<TaskStepper type="vertical" version="v1" />}
          />
        </Routes>
        </BrowserRouter>

        <Background />
      </main>
    </div>
  );
};

export default App;
